





















































































import Vue from 'vue';
import Component from 'vue-class-component';
import {AssociateAccount, BusinessContact, WorkingState} from '@/domain/model/types';
import {Action, Getter} from 'vuex-class';
import constants from '@/common/constants';
import {adminAuth, auth, storage} from '@/plugins/firebase.init';
import {dynamicLinkConfig} from '@/plugins/firebase.config';
import BaseDialog from '@/components/BaseDialog.vue';
import {Prop} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {uuid} from '@/utils/helpers';
import {applicationStore} from '@/store/modules/application';
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendSignInLinkToEmail,
  updateProfile
} from 'firebase/auth';
import {ref, uploadBytes} from 'firebase/storage';
import {businessAssociate, user} from '@/data/firebase';
import {setDoc} from 'firebase/firestore';

@Component({
  name: 'dialog-create-associate',
  components: {AvatarWithStatus, BaseDialog}
})
export default class DialogCreateAssociate extends Vue {
  @Prop() value!: boolean;

  @Getter workingStates!: WorkingState[];
  @Getter directoryOriginals!: BusinessContact[];

  TYPE_CONTACT_PERSONAL = constants.TYPE_CONTACT_PERSONAL;
  backingContact?: BusinessContact | null = null;
  editMode: boolean = false;

  valid: boolean = true;

  createdAssociate: AssociateAccount = {
    fullName: null,
    position: null,
    email: null,
    phoneNumber: null,
    address: null,
    photoUrl: {thumbnail: null, normal: null},
    type: constants.TYPE_ASSOCIATE,
    business: {
      id: null,
      name: null
    }
  };

  associateImageFile: File | null = null;
  associateImageUrl: string | null = null;
  saving: boolean = false;

  @Action onAssociateSelected;

  get business() {
    return applicationStore.business;
  }

  get isOwner() {
    return applicationStore.isOwner;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get roles() {
    return [
      {
        text: 'Super Admin',
        value: 7
      },
      {
        text: 'Admin',
        value: 3
      },
      {
        text: 'Associate',
        value: 1
      }
    ];
  }

  get disableButton() {
    return this.saving;
  }

  get form(): any {
    return this.$refs.createdAssociate;
  }

  onAssociateImageFileSelected(event) {
    console.log('onAssociateImageFileSelected');
    this.associateImageFile = event.target.files[0];
    if (this.associateImageFile) {
      console.log(this.associateImageFile.name);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.associateImageUrl = e.target.result || null;
      };
      reader.readAsDataURL(this.associateImageFile);
    }
  }

  async onSendInvite() {
    if (this.form.validate()) {
      this.saving = true;
      try {
        const {
          fullName,
          position,
          email,
          address,
          type,
          phoneNumber,
          photoUrl,
          admin,
          superAdmin,
          roles
        } = this.createdAssociate;

        const providers = await fetchSignInMethodsForEmail(auth, email!);
        if (providers.length) {
          console.log('Associate account exists');
          alert('Associate account already exists');
        } else {
          console.log('Associate account does not exist. Creating new account...');
          const credentials = await createUserWithEmailAndPassword(adminAuth, email!, uuid());//todo: replace with random password generation
          console.log('Account has been added');
          const profileData = {
            displayName: fullName,
            phoneNumber: phoneNumber || ''
          };
          await updateProfile(credentials.user, profileData);
          await adminAuth.signOut();
          console.log('Profile has been updated');
          // Update successful
          const userData: AssociateAccount = {
            fullName,
            position,
            email,
            phoneNumber,
            photoUrl,
            address,
            type,
            roles,
            business: {id: this.business!.id!, name: this.business!.name},
            workingStatus: {name: 'Absent', type: 2}
          };
          userData.phoneNumber = userData.phoneNumber || null;

          if (roles!.admin) {
            userData.admin = true;
          }

          if (roles!.superAdmin) {
            userData.superAdmin = true;
            userData.admin = true;
          }

          const ops: Promise<any>[] = [];

          const uid = credentials.user!.uid;
          ops.push(setDoc(user(uid), userData, {merge: true}));
          ops.push(setDoc(businessAssociate(userData.business!.id!, uid), userData, {merge: true}));
          console.log(`Associate's user account has been created: ID = ${uid}, data = ${userData}`);

          // Upload profile photo
          if (this.associateImageFile) {
            const path = `users/${uid}/profilePhoto/${this.associateImageFile.name}`;
            // Create a reference to 'images/mountains.jpg'
            const imagesRef = ref(storage, path)
            const snapshot = await uploadBytes(imagesRef, this.associateImageFile);
            console.log(`Profile photo has been uploaded - ${snapshot.ref}`);
          }

          ops.push(sendSignInLinkToEmail(auth, credentials.user!.email!, dynamicLinkConfig.actionCodeSettings));
          await Promise.all(ops);
          console.log(`sign in link has been sent to ${credentials.user!.email}`);
        }
      } catch (error) {
        console.log(error);
      }
      this.saving = false;
      this.show = false;
    } else {
      console.log('New associate declined');
    }
  }

  created() {
    console.log('CreateAssociate :: created');
  }
}
